import * as React from 'react';

export interface IPurchase {
  price: number,
  total: number,

  initialBuy: number,
  dipPrice: number,
  dipAmount: number,
  stopLoss: number,

  tolerance: number,
  expected: number,
  buffer: number,

  profit: number,
}

export interface IPurchaseUpdate {
  price: number,
  total: number,
  expected: number,
  buffer: number,
  tolerance: number,
}

export interface ITradeFormProps {}

export class TradeForm extends React.Component<ITradeFormProps, IPurchase> {
  state: IPurchase = {
    price: 17.2,
    total: 10000,

    initialBuy: 0.0,
    dipPrice: 0.0,
    dipAmount: 0.0,
    stopLoss: 0.0,

    tolerance: 0.9,
    expected: 0.8,
    buffer: 0.3,

    profit: 0.3,
  }

  constructor () {
    super();

    this.state = this.purchase({
      price: 17.2,
      total: 10000,
      expected: 0.8,
      buffer: 0.3,
      tolerance: 0.9,
    });

    this.updatePurchase = this.updatePurchase.bind(this);
  }

  purchase (p: IPurchaseUpdate): IPurchase {
    let dipPrice = p.price * p.expected;
    let dipAmount = p.total - p.total * p.buffer;
    let initialBuy = p.total * p.buffer;
    let stopLoss = p.price * (3 * p.expected - 1) / 2;

    let tokens = p.total * p.buffer / p.price;
    let dip = dipAmount / dipPrice;
    let dipReboundPrice = p.price * p.tolerance;
    let finalValue = (tokens + dip) * dipReboundPrice;

    let profit = finalValue - p.total;

    return {
      price: p.price,
      total: p.total,

      initialBuy,
      dipPrice,
      dipAmount,
      stopLoss,

      tolerance: p.tolerance,
      expected: p.expected,
      buffer: p.buffer,

      profit,
    };
  }

  updatePurchase (e: any) {
    let price = e.target.name === "price" ? e.target.value : this.state.price;
    let total = e.target.name === "total" ? e.target.value : this.state.total;
    let tolerance = e.target.name === "tolerance" ? e.target.value : this.state.tolerance;
    let expected = e.target.name === "expected" ? e.target.value : this.state.expected;
    let buffer = e.target.name === "buffer" ? e.target.value : this.state.buffer;

    let purchase = this.purchase({
      price,
      total,
      expected,
      buffer,
      tolerance,
    });

    this.setState(purchase);
  }

  render () {
    return (
      <div>
        <p>
        <label htmlFor="price">Price</label>
        <input type="number" name="price" min="0" step="0.1" value={this.state.price} onChange={this.updatePurchase} />
        </p>

        <p>
        <label htmlFor="total">Total</label>
        <input type="number" name="total" min="0" value={this.state.total} onChange={this.updatePurchase} />
        </p>

        <hr />

        <p>
        <label htmlFor="tolerance">Tolerance</label>
        <input type="number" name="tolerance" min="0.01" max="1" step="0.01" value={this.state.tolerance} onChange={this.updatePurchase} />
        </p>

        <p>
        <label htmlFor="expected">Expected</label>
        <input type="number" name="expected" min="0.01" max="1" step="0.01" value={this.state.expected} onChange={this.updatePurchase} />
        </p>

        <p>
        <label htmlFor="buffer">Buffer</label>
        <input type="number" name="buffer" min="0.01" max="1" step="0.01" value={this.state.buffer} onChange={this.updatePurchase} />
        </p>

        <hr />

        <p>
        <label htmlFor="initialBuy">Initial Buy</label>
        <input type="number" name="initialBuy" disabled value={this.state.initialBuy} />
        </p>

        <p>
        <label htmlFor="dipPrice">Dip Price</label>
        <input type="number" name="dipPrice" disabled value={this.state.dipPrice} />
        </p>

        <p>
        <label htmlFor="dipAmount">Dip Amount</label>
        <input type="number" name="dipAmount" disabled value={this.state.dipAmount} />
        </p>

        <p>
        <label htmlFor="stopLoss">Stop Loss</label>
        <input type="number" name="stopLoss" disabled value={this.state.stopLoss} />
        </p>

        <hr />

        <p>
        <label htmlFor="profit">Profit</label>
        <input type="number" name="profit" disabled value={this.state.profit} />
        </p>
      </div>
    );
  }
}
