import './App.css';
import { TradeForm } from "./components/Trade.js";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <TradeForm />
      </header>
    </div>
  );
}

export default App;
